import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactForm from "../components/contactForm"

const KajakowyPage = () => (
  <Layout>
    <SEO title="Kajakowy Weekend w Gdańsku" />
    <section className="single-trip-description">
      <div className="wrap">
        <div className="negative-margin-left negative-margin-photo">
          <img src={`/static/kajakowy-weekend.jpg`} alt="" />
        </div>
        <div>
          <h3>WEEKENDOWA WYPRAWA KAJAKIEM PO GDAŃSKU</h3>
          <h1>Kajakowy Weekend w Gdańsku</h1>
          <p>
            Oferta jest ciekawą alternatywą dla tych, którzy chcą aktywnie
            spędzić weekend, ale także zobaczyć historyczne miasto i skosztować
            lokalnej kuchni. Poznacie miasto z innej perspektywy. Dzięki rzece
            Motławie, która przepływa przez Gdańsk zobaczycie z pokładu kajaka
            Główne Miasto, marinę, Filharmonię Bałtycką, statek SS Sołdek, który
            obecnie jest muzeum. Weekend nie obejdzie się oczywiście bez
            degustacji lokalnych specjałów, które pochodzą głównie z ryb
            złowionych w Bałtyku. W Sopocie obowiązkowym punktem zwiedzania jest
            rejs „pirackim” statkiem. To idealna okazja to zobaczenia Zatoki
            Gdańskiej z bliska oraz poznanie Trójmiasta z oddali. Na pokładzie
            można dobrze zjeść i poczuć wiatr we włosach.
          </p>
        </div>
        <div className="trip-plan">
          <h4>W programie proponujemy:</h4>
          <div className="day">
            <div className="day-number">Dzień 1</div>
            <div className="day-plan">
              <ul>
                <li>Transfer z lotniska / dworca PKP</li>
                <li>Zakwaterowanie w hotelu / pensjonacie / apartamencie</li>
                <li>
                  Spacer po Gdańsku i zapoznanie się z najważniejszymi zabytkami
                  w mieście
                </li>
                <li>
                  Degustacja piwa w browarni przy jachtowej przystani w Gdańsku
                </li>
                <li>Nocleg</li>
              </ul>
            </div>
          </div>
          <div className="day">
            <div className="day-number">Dzień 2</div>
            <div className="day-plan">
              <ul>
                <li>
                  Rejs kajakiem po rzece Motławie z widokami na zabytki Gdańska
                  związane z dawnym portem miasta
                </li>
                <li>Obiad w restauracji z lokalnymi specjałami</li>
                <li>
                  Po południu rejs kajakiem – dużą pętlą przez Śródmieście i
                  Dolne Miasto
                </li>
                <li>Nocleg</li>
              </ul>
            </div>
          </div>
          <div className="day">
            <div className="day-number">Dzień 3</div>
            <div className="day-plan">
              <ul>
                <li>
                  Rejs „pirackim” statkiem na Westerplatte, na pokładzie jest
                  bar oraz możliwość koncertu na żywo muzyki szantowej
                </li>
                <li>Transfer na lotnisko / dworzec PKP</li>
              </ul>
            </div>
          </div>
        </div>
        <div>
          <ContactForm />
        </div>
        <div className=" prices">
          <div>
            CENA OD: 450 zł / za osobę
            <p> CENA ZAWIERA</p>
            <ul>
              <li>Transfer </li>
              <li>Zakwaterowanie </li>
              <li>Towarzystwo przewodnika turystycznego </li>
            </ul>
          </div>
          <div>
            Cena zależy od ilości osób, programu i standardu noclegu. Cenę
            kalkulujemy dla każdego klienta indywidualnie, ponieważ zależy ona
            od ilości osób, programu i rodzaju noclegu. Każdy program
            dostosowany jest do życzeń naszych gości oraz do ich harmonogramu
            podróży.
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default KajakowyPage
